import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import fieldHelper from '@/helpers/field';

const FieldRepository = RepositoryFactory.get('field');

const state = {
  loading: false,
  fields: null,
  field: null,
  productConfig: {
    code: null, // p13 etc
    scale: null, // relative or absolute
    layerIndex: 0 // index in array of layers
  }
}

const actions = {
  loading ({ commit }) {
    commit('SET_LOADING');
  },
  reset ({ commit }) {
    commit('RESET_FIELD');
  },
  getField ({ commit }, fieldId) {
    return new Promise((resolve, reject) => {
      const getField = FieldRepository.getFieldById(fieldId);
      const getProducts = FieldRepository.getFieldProducts(fieldId);

      Promise.all([getField, getProducts]).then((values) => {
        const field = {
          ...values[0].data.data,
          products: values[1].data.data
        }

        commit('SET_FIELD', field);
        resolve(field);
      }).catch((error) => {
        commit('SET_FIELD', null);
        reject(error);
      });
    })
  },
  getFieldLayers ({ commit }, { fieldId, params }) {
    return new Promise((resolve, reject) => {
      return FieldRepository.getFieldProductLayers(fieldId, params).then(response => {
        const code = params.code;
        const colormap = {
          slug: params.scale,
          layers: response.data.data
        }

        commit('SET_FIELD_COLORMAP', {
          code,
          colormap
        });

        resolve(colormap);
      }).catch(response => {
        reject(response);
      });
    })
  },
  getFieldStatistics ({ commit }, { fieldId, params }) {
    return new Promise((resolve, reject) => {
      return FieldRepository.getFieldStatistics(fieldId, params).then(response => {
        const statistics = response.data.data;
        resolve(statistics);
      }).catch(response => {
        reject(response);
      });
    })
  },
  getFieldSoilMoisture ({ commit }, { fieldId, params }) {
    return new Promise((resolve, reject) => {
      return FieldRepository.getFieldSoilMoisture(fieldId, params).then(response => {
        const statistics = response.data.data;
        resolve(statistics);
      }).catch(response => {
        reject(response);
      });
    })
  },
  create ({ commit }, geoJson) {
    return new Promise((resolve, reject) => {
      return FieldRepository.create(geoJson).then(response => {
        const field = response.data.data;
        resolve(field);
      }).catch((error) => {
        commit('SET_FIELD', null);
        reject(error);
      });
    })
  },
  find ({ commit }, coordinates) {
    return new Promise((resolve, reject) => {
      return FieldRepository.find(coordinates).then(response => {
        const field = response.data.data;
        resolve(field);
      }).catch((error) => {
        commit('SET_FIELD', null);
        reject(error);
      });
    })
  },
  resetBrpField ({ commit }, fieldId) {
    return new Promise((resolve, reject) => {
      return FieldRepository.resetBrpField(fieldId).then(response => {
        const field = response.data.data;
        commit('SET_FIELD', field);
        resolve(field);
      }).catch((error) => {
        commit('SET_FIELD', null);
        reject(error);
      });
    })
  },
  getFieldProductLayers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return FieldRepository.getProductLayers(payload.id, payload).then(response => {
        const layers = response.data.data;
        commit('ADD_PRODUCT_LAYERS', layers);
        resolve(layers);
      }).catch((error) => {
        commit('ADD_PRODUCT_LAYERS', null);
        reject(error);
      });
    })
  },
  getFields ({ commit }) {
    return new Promise((resolve, reject) => {
      return FieldRepository.getAllFields().then(response => {
        const fields = response.data.data;
        commit('SET_FIELDS', fields);
        resolve(fields);
      }).catch((error) => {
        commit('SET_FIELDS', null);
        reject(error);
      });
    })
  },
  updateFieldById ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return FieldRepository.updateFieldById(payload.fieldId, payload.body).then(response => {
        const field = response.data.data;
        commit('UPDATE_FIELD', field);
        resolve(field);
      }).catch((error) => {
        reject(error);
      });
    })
  },
  updateSeasonsFieldById ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return FieldRepository.updateSeasonsFieldById(payload.fieldId, payload.body).then(response => {
        const seasons = response.data.data;
        resolve(seasons);
      }).catch((error) => {
        reject(error);
      });
    })
  },
  resetProductConfig ({ commit }) {
    commit('SET_PRODUCT_CONFIG', {
      code: null,
      scale: null,
      layerIndex: 0
    });
  },
  setProductConfig ({ commit, getters }, productConfig) {
    const code = productConfig.code || getters.getProductConfig.code;
    const product = getters.getProducts.find(product => product.code === code);

    if (!state.productConfig.scale || !fieldHelper.hasProductColormap(product, state.productConfig.scale)) {
      productConfig.scale = product.meta.preferred;
    }

    commit('SET_PRODUCT_CONFIG', productConfig);
  },
  setColormapCurrentLayerIndex ({ commit }, index) {
    commit('SET_COLORMAP_CURRENT_LAYER', index);
  }
}

const mutations = {
  SET_LOADING () {
    state.loading = !state.loading;
  },
  RESET_FIELD (state) {
    state.field = null;
  },
  SET_FIELD (state, field) {
    state.field = field
  },
  SET_FIELD_COLORMAP (state, { code, colormap }) {
    const productIndex = state.field.products.findIndex(product => product.code === code);
    const productColormaps = state.field.products[productIndex].colormaps || [];
    const productColormapIndex = productColormaps.findIndex(colormap => colormap.slug === colormap.scale);

    if (productColormapIndex !== -1) {
      productColormaps[productColormapIndex] = colormap
    } else {
      productColormaps.push(colormap)
    }

    state.field.products[productIndex].colormaps = productColormaps;
    state.field = { ...state.field }
  },
  UPDATE_FIELD (state, field) {
    // Only update state when active field is
    //  the same as the updated field...
    if (state.field.id !== field.id) return;

    state.field = { ...state.field, ...field }
  },
  SET_FIELDS (state, fields) {
    state.fields = fields;
  },
  SET_PRODUCT_CONFIG (state, productConfig) {
    state.productConfig = {
      ...state.productConfig,
      ...productConfig
    };
  },
  SET_COLORMAP_CURRENT_LAYER (state, index) {
    state.productConfig.layerIndex = index;
  }
}

const getters = {
  loading: state => state.loading,
  getField: state => state.field,
  getFieldName: state => {
    return state.field && typeof state.field === 'object' && state.field.name ? state.field.name : '';
  },
  getFields: state => state.fields,
  getGeometry: state => {
    const geometry = state.field && state.field.geometry ? state.field.geometry : '{}';
    return {
      type: 'Feature',
      id: 1,
      geometry: JSON.parse(geometry)
    }
  },
  getProducts: state => {
    const products = state.field && state.field.products ? state.field.products : [];
    return products;
  },
  getProductConfig: state => state.productConfig,
  getProduct: state => {
    return state.field.products.find(product => product.code === state.productConfig.code)
  },
  getProductStatus: (state, getters) => (status) => {
    const product = getters.getProduct;
    return product && product.status === status;
  },
  getProductColormap: (state, getters) => {
    const product = getters.getProduct;

    if (!state.field || !product || !product.colormaps) return null;

    return product.colormaps.find((colormap) => colormap.slug === state.productConfig.scale) || null;
  },
  getProductColormapLayers: (state, getters) => {
    const colormap = getters.getProductColormap;
    const layers = colormap && colormap.layers ? colormap.layers : [];

    return layers.map((layer, index) => {
      return {
        id: layer.id,
        value: layer.created_at,
        active: index === state.productConfig.layerIndex
      }
    })
  },
  getProductColormapCurrentLayer: (state, getters) => {
    // Use getters to retrieve data
    const colormap = getters.getProductColormap;
    const productConfig = getters.getProductConfig;

    // Extract layers
    const layers = colormap && colormap.layers ? colormap.layers : [];

    // Return the active layer
    return (layers.length ? layers[productConfig.layerIndex] : {})
  },
  getProductColormapCurrentLayerMeta: (state, getters) => {
    const productColormapCurrentLayer = getters.getProductColormapCurrentLayer;
    return productColormapCurrentLayer && productColormapCurrentLayer.meta ? productColormapCurrentLayer.meta : {};
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
